<template>
  <PreviewSpiner v-if="!ready"/>
  <div v-else>
    <div class="create-page">
      <CForm
        v-on:submit.prevent="createSingleStep"
        novalidate
        class="create-page__form"
      >
        <FormBuilder
          :list="formList"
          @updated="updateSettingsData"
          :page="{ title: texts.createPage.title, info: descriptions }"
        />
      </CForm>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { computeEngines } from '@/config/descriptions/computeEngines.json';
import { computeEnginesTexts } from '@/config/pageTexts/computeEngines.json';
import computeEngineFields from '@/generated/ziqni/store/modules/computeEngines/fields';
import { delay } from 'lodash';
import PreviewSpiner from "@/shared/UI/Spiner";

export default {
  name: 'CreateComputeEngine',
  components: {
    PreviewSpiner,
  },
  data() {
    return {
      model: 'computeEngines',
      ready: true,
      descriptions: {
        ...computeEngines.create,
      },
      texts: {
        ...computeEnginesTexts,
      },
      formList: [],
      settingsData: {},
      requiredFields: [],
    };
  },
  computed: {
    ...mapGetters('computeEngines', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(
      'computeEngines',
      [
        'handleCreateComputeEngines',
      ]
    ),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        computeEngineFields,
        computeEnginesTexts.createPage,
        computeEngines.create
      );

      this.formList.forEach(field => {
        if (field.required) {
          this.requiredFields.push(field.key);
          if (field.type === 'NAMEKEY') {
            this.requiredFields.push('name');
          }
        }
      })
    },
    getInvalidFields() {
      let result = [];
      //TODO: remove after implementing constraint
      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }

      return result;
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.constraints;
        formData = {...this.settingsData};
      }

      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      const body = JSON.parse(JSON.stringify(formData));

      this.handleCreateComputeEngines({createComputeEngineRequest: body})
        .then(data => {
          if (data.length) {
            this.ready = false;
            delay(() => {
              this.$router.push({
                name: 'PreviewComputeEngine',
                params: {
                  id: data[0].id,
                }
              })
            }, 1500);
          } else {
            console.log('Something went wrong');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
